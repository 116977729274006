@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root
{
  --theme-red: #ce4437;
  --theme--dark-blue: #011b2d;
  --theme-light-blue: #011f35;
  --theme-light-blue-mod: #03365a;
  --theme-dark-blue-transparent: #011f35d2;
  --theme-light-grey: #ededed;
  --theme-dark-grey: #6a6969;
  --theme-medium-grey: #b8b7b7;
  --theme-darkest-grey: #212529;
  --theme-footer-grey: #141414;
  --theme-white: white;
}
::selection {
  background-color: var(--theme-red); /* Background color of selected text */
  color: var(--theme-white); /* Text color of selected text */
}

/* Webkit browsers (Safari, Chrome) also support a prefixed version */
::-webkit-selection {
  background-color: var(--theme-red); /* Background color of selected text */
  color: var(--theme-white); /* Text color of selected text */
}
.ls-small{
  letter-spacing: 0.7px;
}
.ls-medium{
  letter-spacing: 1px;
}
.ls-large{
  letter-spacing: 1.5px;
}
.ls-larger{
  letter-spacing: 2px;
}

.bg-theme-red
{
  background-color: var(--theme-red);
}
.bg-theme-light-blue
{
  background-color: var(--theme-light-blue);
}
.bg-theme-light-blue-mod
{
  background-color: var(--theme-light-blue-mod);
}
.bg-theme-dark-blue
{
  background-color: var(--theme--dark-blue);
}
.bg-theme-light-grey
{
  background-color: var(--theme-light-grey);
}
.bg-theme-dark-grey
{
  background-color: var(--theme-dark-grey);
}
.bg-theme-darkest-grey
{
  background-color: var(--theme-darkest-grey);
}
.bg-theme-footer-grey
{
  background-color: var(--theme-footer-grey);
}
.bg-theme-dark-blue-transparent
{
  background-color: var(--theme--dark-blue);
  /* background-color: var(--theme-dark-blue-transparent); */
}
.color-theme-red
{
  color: var(--theme-red);
}
.color-theme-light-grey
{
  color: var(--theme-light-grey);
}
.color-theme-dark-blue
{
  color: var(--theme-dark-blue);
}
.color-theme-medium-grey
{
  color: var(--theme-medium-grey);
}
.color-theme-dark-grey
{
  color: var(--theme-dark-grey);
}
.color-theme-darkest-grey
{
  color: var(--theme-darkest-grey);
}
*
{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 600;
  font-style: normal;
}
.font-roboto
{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-mont
{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.myborder
{
  border: 5px solid red;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.display-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex-align-items-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.display-flex-align-items-top-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.display-flex-align-items-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.display-flex-align-items-top-between
{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.fs-smallest-css {
  font-size: 0.4em;
}
.fs-smaller-css {
  font-size: 0.6em;
}
.fs-medium-small-css
{
  font-size: 0.75em;
}
.fs-slight-small-css {
  font-size: 13px;
  /* font-size: 0.9em; */
}
.fs-small-css {
  font-size: 0.6em;
}
.fs-medium-css {
  font-size: 1.2em;
}
.fs-slight-large-css
{
  font-size: 1.6em;
}
.fs-large-css {
  font-size: 2em;
}
.fs-larger-css {
  font-size: 3em;
}
.fs-largest-css {
  font-size: 4em;
}
.fs-huge-css
{
  font-size: 7em;
}
@media (min-width:900px) and (max-width:4000px)
{
  .display-on-large-screen
  {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-small-screen
  {
    display: none;
  }
  .bg-light-grey-responsive
  {
    background-color: transparent;
  }
}
@media (min-width:0px) and (max-width:900px)
{
  .bg-light-grey-responsive
  {
    background-color: var(--theme-light-grey);
  }
  .display-on-small-screen
  {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-large-screen
  {
    display: none;
  }
}
.height-navbar
{
  height: 10vh;
}
.btn-navbar
{
  color: var(--theme-white);
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px 0px;
  transition: all 0.6s;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.4px;
}
.btn-navbar:hover , .btn-navbar:focus
{
  color: var(--theme-dark-grey);
}
.btn-navbar-articles
{
  color: var(--theme-footer-grey);
  background-color: transparent;
  border: none;
  outline: none;
  /* padding: 10px 20px; */
  transition: all 0.6s;
  font-size: 12px;
  font-weight: 600;
}
.btn-navbar-articles:hover , .btn-navbar-articles:focus
{
  color: var(--theme-dark-grey);
}
.nav-btn-container
{
  /* width: 15%; */
  width: auto;
}
.nav-btn-dropdown
{
  padding: 10px 20px;
  background-color: var(--theme-darkest-grey);
  width: 20vw;
  transition: all 0.6s;
}
.scale-y-0
{
  transform-origin: top;
  transform: scaleY(0);
}
.scale-y-1
{
  transform-origin: top;
  transform: scaleY(1);
}
/* .height-100vh 
{
  height: 100vh;
} */
.position-relative
{
  position: relative;
}
.position-absolute-top
{
  position: absolute;
  top: 0px;
}
.object-fit-cover
{
  object-fit: cover;
}
.slider
{
  z-index: -1;
  height: 100%;
  width: 100vw;
  position: relative;
}
.min-height-50vh
{
  min-height: 60vh;
}
.slide
{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 1s;
}
.slide.active
{
  opacity: 1;
}
.navbar-position
{
  transition: all 0.6s;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
}
.mainpage-black-wash 
{
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.264);
  z-index: 0;
}

.slide-content-container 
{
  position: absolute;
  top: 0px;
  height: 100vh;
  z-index: 2;
  opacity: 0;
  transition: all 1s;
  /* transform: translateY(-30%); */
  z-index: -1;
  transition-delay: 1s;
}
.slide-content-container.active
{
  /* transition-delay: 1s; */
  z-index: 2;
  opacity: 1;
  /* transform: translateY(0%); */
}
.slide-content-container:not(.active)
{
  transition-delay: 0s;
  opacity: 0;
  /* transform: translateY(30%); */
  z-index: -1;
}
.color-theme-white
{
  color: var(--theme-white);
}
.slide-content-tagline
{
  color: var(--theme-light-grey);
  letter-spacing: 2px;
  font-size: 0.8em;
  font-weight: 500;
}
.btn-universal-transparent-bg
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 15px 25px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
  letter-spacing: 1.6px;
  font-weight: 800;
}
.btn-universal-transparent-bg:hover, .btn-universal-transparent-bg-focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-transparent-bg-blue
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 8px 25px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
  letter-spacing: 1.6px;
  font-weight: 800;
}
.btn-universal-transparent-bg-blue:hover, .btn-universal-transparent-bg-blue:focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-transparent-bg-blue:disabled
{
  background-color: transparent;
  border: 1px solid var(--theme-dark-grey);
  color: var(--theme-dark-grey);
}
.btn-universal-red
{
  color: var(--theme-white);
  background-color: var(--theme-red);
  padding: 10px 15px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
  width: auto;
}
.btn-universal-red:hover 
{
  color: var(--theme-red);
  background-color: var(--theme-white);
}

.btn-universal-red-converse
{
  color: var(--theme-red);
  background-color: transparent;
  /* padding: 10px 15px; */
  outline: none;
  border: 1px solid transparent;
  transition: all 0.6s;
  border-radius: 0%;
  
  width: auto;
}
.btn-universal-red-converse:hover 
{
  color: var(--theme-red);
  background-color: var(--theme-white);
}
.btn-universal
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 15px 25px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
}
.btn-universal:hover , .btn-universal:focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-converse
{
  color: var(--theme-white);
  background-color: var(--theme--dark-blue);
  padding: 15px 25px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.6s;
  border-radius: 2%;
}
.btn-universal-converse:hover , .btn-universal-converse:focus
{
  color: var(--theme--dark-blue);
  background-color: var(--theme-white);
  
  border: 1px solid var(--theme--dark-blue);
}
.btn-universal-converse:disabled
{
  opacity: 0.6;
  background-color: var(--theme--dark-blue);
  color: var(--theme-white);
}
.slide-number-container
{
  /* position: absolute;
  bottom: 20px; */
  z-index: 2;
}
.overflow-hidden
{
  overflow-y: hidden;
}
.z-index-99
{
  z-index: 99;
}
.position-relative-top
{
  position: relative;
  top: 0px;
}
.z-index--1
{
  z-index: -1;
}
.text-align-justify
{
  text-align: justify;
}
.bg-banner-image
{
  background-image: url("https://incubeindia.com/incubelandmarks/images/welcome-image-mod.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
.h-120
{
  height: 120%;
}
.border-dark-grey
{
  border: 1px solid var(--theme-dark-grey);
}
.value-icon
{
  height: 100px;
  width: 100px;
  background-color: var(--theme-red);
  color: var(--theme-white);
  border: none;
  outline: none;
}
.why-choose-us-image 
{
  height: auto;
  width: 100%;
  object-fit: cover;
}
.homepage-form-bg-image 
{
  background-image: url("https://incubeindia.com/incubelandmarks/images/form-bg.webp");
  background-size: cover;
}
.bg-blue-transparent
{
  background-color: #00436cad;
  z-index: 1;
}
.red-line
{
  border-top: 0.5px solid var(--theme-red);
  width: 100px;
}
.red-line-project
{
  border-top: 2px solid var(--theme-red);
  width: 100px;
}
.testimonial-image
{
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.blog-image
{
  height: 35vh;
  object-fit: cover;
}
.border-top-dark-grey
{
  border-top: 0.5px solid var(--theme-dark-grey);
}
.blog-image-hover
{
  height: 35vh;
  z-index: 2;
  background-color: #00436c99;
  position: absolute;
  top: 0px;
  opacity: 0;
  transition: all 0.6s;
}
.blog:hover .blog-image-hover
{
  opacity: 1;
}
.blog:focus .blog-image-hover
{
  opacity: 1;
}
.btn-plus
{
  background-color: transparent;
  color: var(--theme-white);
  border: none;
  outline: none;
}
.cursor-pointer:hover
{
  cursor: pointer;
}
.btn-footer
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 0px 10px;
  outline: none;
  border: none;
  transition: all 0.6s;
}
.btn-footer:hover , .btn-footer:focus
{
  color: var(--theme-red);
}
.color-article
{
  color: var(--theme-dark-grey);
  transition: all 0.6s;
}
.color-article:hover , .color-article:focus
{
  color: var(--theme-red);
}
.input-field-footer
{
  background-color: transparent;
  color: var(--theme-white);
  padding: 10px 20px;
  font-size: 0.8em;
  font-weight: 500;
   border: 0.5px solid var(--theme-dark-grey);
   outline : none;
   transition: all 0.6s;
}
.input-field-footer:focus
{
  border: 0.5px solid var(--theme-white);
}
.header 
{
  height: 100%;
  background-image: url("https://incubeindia.com/incubelandmarks/images/header-image.webp");
  background-size: cover;
  position: absolute;
  top: 0px;
}
.header-container
{
  height: 50vh;
  z-index: 98;
}
.opacity-half
{
  opacity: 0.6;
}
.header-black-wash
{
  position: absolute;
  top: 0px;
  background-color: var(--theme-dark-blue-transparent);
  height: 100%;
}
.bg-grey-shapes
{
  background-image: url("https://www.themezaa.com/html/pofo/images/parallax-bg18.jpg");
  background-size: cover;
}
.bg-grey-shapes-image
{
  background-image: url("https://www.themezaa.com/html/pofo/images/parallax-bg18.jpg");
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: -1;
}
.about-us-card-image
{
  height: 35vh;
  object-fit: cover;
  width: 100%;
} 
.about-us-fix-bg-image
{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0px;
  z-index: -1;
  background-image: url("https://incubeindia.com/incubelandmarks/images/about-us-video-bg.webp");
  background-size: cover;
  background-position: center;
}
.bg-black-wash
{
  background-color: var(--theme-dark-blue-transparent);
}
.btn-play
{
  background-color: transparent;
  height: 80px;
  width: 80px;
  border: 3px solid var(--theme-white);
  outline: none;
  color: var(--theme-white);
  transition: all 0.6s;
}
.btn-play:hover
{
  border: 3px solid transparent;
}
.btn-universal
{
  background-color: white;
  color: var(--theme--dark-blue);
  border: 1px solid var(--theme--dark-blue);
  outline: none;
  font-size: 0.8em;
  letter-spacing: 1px; 
}
.btn-universal:hover , .btn-universal:focus
{
  background-color: var(--theme--dark-blue);
  color: var(--theme-white);
  border: 1px solid transparent;
}
.btn-universal:disabled
{
  opacity: 0.6;
  border: 1px solid transparent;
  background-color: var(--theme--dark-blue);
  color: var(--theme-white);
}
.border-left-grey
{
  border-left: 0.5px solid var(--theme-dark-grey);
}
.border-left-red
{
  border-left: 1.5px solid var(--theme-red);
}
.services-card-image
{
  height: 60vh;
  object-fit: cover;
}
.position-service-card-content
{
  position: absolute;
  bottom: 0px;
  transform: translateY(15vh);
  transition: all 0.6s;
}
.service-card-heading
{
  border-bottom: 0.5px solid var(--theme-dark-grey);
}
.service-card-description
{
  height: 15vh;
}
.service-card
{
  overflow-y: hidden;
}
.service-card:hover .position-service-card-content
{
  transform: translateY(0vh);
}
.text-align-left
{
  text-align: left;
}
.text-align-right
{
  text-align: right;
}
.feature-card 
{
  min-height: 30vh;
}
.features-container::-webkit-scrollbar
{
  width: 0px;
}
.input-field
{
  color: var(--theme-dark-grey);
  font-weight: 400;
  background-color: var(--theme-white);
  outline: none;
  padding: 15px 25px;
  font-size: 1em;
  border: 0.5px solid var(--theme-medium-grey);
  transition: all 0.6s;
}
.input-field-blue
{
  color: var(--theme-dark-grey);
  font-weight: 400;
  background-color: var(--theme-white);
  outline: none;
  padding: 6px 25px;
  font-size: 0.7em;
  border: 0.5px solid var(--theme-medium-grey);
  transition: all 0.6s;
  border-radius: 0px;
}
.input-field:focus{
  border: 0.5px solid var(--theme-red);
}
.input-field-blue:focus{
  border: 0.5px solid var(--theme-red);
}
.input-field:disabled
{
  background-color: var(--theme-medium-grey);
}
.input-field-blue:disabled
{
  background-color: var(--theme-medium-grey);
}
.btn-social
{
  height: 70px;
  width: 70px;
  position: relative;
  border-radius: 100%;
  color: var(--theme--dark-blue);
  border: 0.5px solid var(--theme-medium-grey);
  transition: all 0.6s;
  background-color: transparent;
}
.btn-social-header
{
  height: 70px;
  width: 70px;
  position: relative;
  border-radius: 100%;
  color: var(--theme-white);
  border: 0.5px solid transparent;
  transition: all 0.6s;
  background-color: transparent;
}
.btn-social-scaler-bg-header
{
  position: absolute;
  top: 0px;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.6s;
  z-index: 1;
}
.btn-social-scaler-bg
{
  position: absolute;
  top: 0px;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.6s;
  z-index: 1;
}
.btn-social-icon
{
  z-index: 2;
}
.btn-social:hover .btn-social-scaler-bg
{
  transform: scale(1);
}
.btn-social:focus .btn-social-scaler-bg
{
  transform: scale(1);
}
.btn-social:hover , .btn-social:focus
{
  border: 0.5px solid transparent;
  color: var(--theme-white);

}

.btn-social-icon-header
{
  z-index: 2;
}
.btn-social-header:hover .btn-social-scaler-bg-header
{
  transform: scale(1);
}
.btn-social-header:focus .btn-social-scaler-bg-header
{
  transform: scale(1);
}
.btn-social-header:hover , .btn-social-header:focus
{
  border: 0.5px solid transparent;
  color: var(--theme-white);

}
.bg-facebook-blue
{
  background-color:#4267B2;
}
.bg-twitter-blue
{
  background-color: #1DA1F2;
}
.bg-google-plus-red
{
  background-color: #DB4437;
}
.bg-whatsapp-green
{
  background-color: #25D366;
}
.bg-linkedin-blue
{
  background-color: #0072b1;
}
.btn-internal-navbar
{
  background-color: transparent;
  outline: none;
  border: none;
  opacity: 0.8;
  color: var(--theme-dark-grey);
  font-size: 0.8em;
  letter-spacing: 1.5px;
  font-weight: 500;
  transition: all 0.6s;
}
.btn-internal-navbar:focus
{
  opacity: 1;
  color: black;
  text-decoration: underline;
  text-underline-offset: 8px;
}
.project-height-100
{
  height: 81.3vh;
}
.project-height-50
{
  height: 40vh;
}
.projects-container
{
  transition: all 0.6s;
}
.project-hover-bg
{
  background-color: var(--theme-dark-blue-transparent);
  position: absolute;
  top: 0px;
  opacity: 0;
  transition: all 0.6s;
  z-index: 1;
}
.project
{
  z-index: 2;
}
.project:hover .project-hover-bg
{
  z-index: 3;
  opacity: 1;
}
.project:focus .project-hover-bg
{
  z-index: 3;
  opacity: 1;
}
.avni-page-fixed-bg-image 
{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  z-index: -2;
  background-image: url("https://incubeindia.com/incubelandmarks/images/avni-fixed-bg-image.webp");
  background-size: cover;
}
.border-bottom-dark-grey
{
  border-bottom: 0.5px solid var(--theme-medium-grey);
}
.faq-question
{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid var(--theme-medium-grey);
}
.faq-answer
{
  transition: height 0.3s  ease-in-out;
  transform-origin: top;
  height: 0vh;
  overflow: hidden;
}
.overflow-all-hidden
{
  overflow: hidden;
}
.faq-answer.active
{
  padding: 20px 0px;
  transition: height 0.3s ease-in-out;
  height: auto;
}
.faq-answer:not(.active)
{
  padding: 0px;
  transition: height 0.3s ease-in-out;
  height: 0px;
}
.article-image
{
  /* height: 100vh; */
  object-fit: cover;
}
.on-hover-color-red:hover
{
  transition: all 0.6s;
  color: var(--theme-red);
}
.article-cotent 
{
  border: 0.5px solid var(--theme-light-grey);
}
.border-right-medium-grey
{
  border-right: 0.5px solid var(--theme-light-grey);
}
.article-author-image
{
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 100%;
}
.article-author-image-inside
{
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 100%;
}
.margin-top-15vh
{
  margin-top: 12vh;
}
.border-medium-grey
{
  border: 0.5px solid var(--theme-medium-grey);
}
.min-height-80vh
{
  min-height: 80vh;
}
.blur-bg
{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  margin: 0px;
  z-index: 3;
  filter: blur(5px);
  background-color: var(--theme-dark-blue-transparent);
}
.z-index-2
{
  z-index: 2;
}
.height-0{
  height: 0vh;
  transition: all 0.6s;
  transform-origin: top;
  opacity: 0;
}
.height-auto
{
  height: auto;
  transition: all 0.6s;
  transform-origin: top;
  opacity: 1;
}
.input-field.wrong-input
{
  outline: 1px solid red;
  color: red;
  box-shadow: 2px 2px 10px red;
}
.input-field-footer.wrong-input
{
  outline: 1px solid red;
  box-shadow: 2px 2px 10px red;
}
.input-field-blue.wrong-input
{
  outline: 1px solid red;
  color: red;
  box-shadow: 2px 2px 10px red;
}
.loading-container
{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 99;
  background-color: var(--theme-dark-blue-transparent);
}
.z-index-100
{
  z-index: 100;
}
.p-carousel-next, .p-carousel-prev
{
  color: var(--theme-white);
  padding: 0px 15px;
  z-index: 9999;
}
.p-carousel-next{
  position: absolute;
  top: center;
  right: 0px;
}
.p-carousel-next > svg, .p-carousel-prev > svg
{
  width: 30px !important;
  height: 30px !important;
}
.p-carousel-prev
{
  position: absolute;
  top: center;
  left: 0px;

}
.p-carousel, .p-component, .p-carousel-horizontal, .p-carousel-content
{
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  transition: all 2s;
}
.p-carousel-item, .p-carousel-item-active, .p-carousel-item-start, .p-carousel-item-end
{
  transition: all 2s ease-in-out;
}
.p-carousel-container .p-carousel-item
{
  position: relative;
  transition: all 2s ease-in-out;
}
.p-carousel, .p-carousel-header, .p-carousel-footer, .p-carousel-content, .p-carousel-container, .p-carousel-items-content, .p-carousel-indicators, .p-carousel-indicator
{
  transition: all 2s ease-in-out;
}
.transition-2-sec
{
  transition: all 2s ease-in-out;
}
.display-flex-column-align-items-top
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.bg-dotted 
{
  background-image: url("https://www.themezaa.com/html/pofo/images/testimonial-bg.png");
  background-size: cover;
  background-color: #f1efefd9;
}
.testimony-dp
{
  height: 100px;
  width: 100px;
  border-radius: 100%;
  object-fit: cover;
  border: 4px solid var(--theme-white);
}
.testimony-bottom-section 
{
  margin-top: -4%;
}
.display-flex-start-align-items-top
{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.timeline-div
{
  transform: translateX(-8px);
}
@media (min-width:1250px) and (max-width:5000px)
{
  .why-choose-us-section-height
  {
    height: 90vh;
  }
  .height-55vh
{
  height: 20em;
}
.height-60vh
{
  height: 30em;
}.height-100vh 
{
  height: 100vh;
}
.triangle
{
  right: -11.5%;
  z-index: 0;
}
.triangle-left{
  left: -13%;
  z-index: 0;
}
.enquiry-form-container
{
  width: 30vw;
  transition: all 0.6s;
}
.enquiry-container
{
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 35vh;
  transition: all 0.6s;
  transform-origin: right;
  transform: translateX(-31vw);
}
.btn-universal-red
{
  font-size: 1em;
}
.article-image
{
  height: 90vh;
}
}
@media (min-width:1100px) and (max-width:1250px)
{
  .why-choose-us-section-height
  {
    height: 90vh;
  }
  .height-55vh
{
  height: 22em;
}
.height-60vh
{
  height: 70vh;
}.height-100vh 
{
  height: 100vh;
}
.triangle
{
  right: -14%;
  z-index: 0;
}
.triangle-left{
  left: -17%;
  z-index: 0;
}
.enquiry-form-container
{
  width: 30vw;
  transition: all 0.6s;
}
.height-100vh 
{
  height: 100vh;
}
.enquiry-container
{
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 35vh;
  transition: all 0.6s;
  transform-origin: right;
  transform: translateX(-31vw);
}
.btn-universal-red
{
  font-size: 1em;
}
.article-image
{
  height: 90vh;
}
}
@media (min-width:992px) and (max-width:1100px)
{
  .triangle
  {
    right: -16%;
    z-index: 0;
  }
}
@media (min-width:900px) and (max-width:1100px)
{
  .why-choose-us-section-height
  {
    height: 110vh;
  }
  .height-55vh
{
  height: 23em;
}
.height-60vh
{
  height: 75vh;
}
.height-100vh 
{
  height: 100vh;
}
.triangle
  {
    display: none;
  }
  
.triangle-left{
  display: none;
}
  .enquiry-form-container
{
  width: 40vw;
  transition: all 0.6s;
}
.height-100vh 
{
  height: 100vh;
}
.enquiry-container
{
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 35vh;
  transition: all 0.6s;
  transform-origin: right;
  transform: translateX(-41vw);
}
.btn-universal-red
{
  font-size: 1em;
}
.article-image
{
  height: 70vh;
}
}
@media (min-width:750px) and (max-width:900px)
{
  .why-choose-us-section-height
  {
    height: auto;
  }
  .height-55vh
{
  height: 22.5em;
}
.height-60vh
{
  height: 55vh;
}
.height-100vh 
{
  height: 100vh;
}
.triangle
  {
    display: none;
  }
  .triangle-left{
    display: none;
  }
  .enquiry-form-container
{
  width: 40vw;
  transition: all 0.6s;
}
.enquiry-container
{
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 35vh;
  transition: all 0.6s;
  transform-origin: right;
  transform: translateX(-40.5vw);
}
.btn-universal-red
{
  font-size: 0.9em;
}
.article-image
{
  height: 70vh;
}

}
@media (min-width:600px) and (max-width:750px)
{
  .why-choose-us-section-height
  {
    height: auto;
  }
  .height-55vh
{
  height: auto;
}
.height-60vh
{
  height: 50vh;
}
.height-100vh 
{
  height: 100vh;
}
.triangle
  {
    display: none;
  }
  .triangle-left{
    display: none;
  }
  .enquiry-form-container
  {
    width: 70vw;
    transition: all 0.6s;
  }
  .enquiry-container
  {
    position: fixed;
    z-index: 9999;
    left: 0px;
    top: 35vh;
    transition: all 0.6s;
    transform-origin: right;
    transform: translateX(-70.5vw);
  }
  .btn-universal-red
{
  font-size: 0.7em;
}
.article-image
{
  height: 70vh;
}
}
@media (min-width:450px) and (max-width:600px)
{
  .why-choose-us-section-height
  {
    height: auto;
  }
  .height-55vh
{
  height: auto;
}
.height-60vh
{
  height: 50vh;
}
.triangle
  {
    display: none;
  }
  .height-100vh 
{
  height: 100vh;
}
  .triangle-left{
    display: none;
  }
  .enquiry-form-container
  {
    width: 70vw;
    transition: all 0.6s;
  }
  .enquiry-container
  {
    position: fixed;
    z-index: 9999;
    left: 0px;
    top: 35vh;
    transition: all 0.6s;
    transform-origin: right;
    transform: translateX(-70.2vw);
  }
  .btn-universal-red
{
  font-size: 0.7em;
}
.article-image
{
  height: 50vh;
}
}
@media (min-width:200px) and (max-width:450px)
{
  .why-choose-us-section-height
  {
    height: auto;
  }
  .height-55vh
{
  height: auto;
}
.height-60vh
{
  height: 45vh;
}
.height-100vh 
{
  height: 100vh;
}
.triangle
  {
    display: none;
  }
  .triangle-left{
    display: none;
  }
  .enquiry-form-container
  {
    width: 70vw;
    transition: all 0.6s;
  }
  .enquiry-container
  {
    position: fixed;
    z-index: 9999;
    left: 0px;
    top: 35vh;
    transition: all 0.6s;
    transform-origin: right;
    transform: translateX(-70vw);
  }
  .btn-universal-red
{
  font-size: 0.6em;
}
.article-image
{
  height: 50vh;
}
}
.size-89
{
  height: 89.6px;
  width: 89.6px;
  object-fit: cover;
}
.height-50px
{
  height: 70px;
}
.translate-counter-1700
{
  will-change: transform;
  transform: translateY(-1700%);
  -webkit-transform: translateY(-1700%); /* For Safari */
    -moz-transform: translateY(-1700%);    /* For Firefox */
    -ms-transform: translateY(-1700%);     /* For Internet Explorer */
    -o-transform: translateY(-1700%);
  transition: all 2.2s ease-out;
}
/* .translate-counter-1500
{
  transform: translateY(-1600%);
  transition: all 2.2s ease-out;
} */
.translate-counter-1100
{
  will-change: transform;
  transform: translateY(-1100%);
  -webkit-transform: translateY(-1100%); /* For Safari */
  -moz-transform: translateY(-1100%);    /* For Firefox */
  -ms-transform: translateY(-1100%);     /* For Internet Explorer */
  -o-transform: translateY(-1100%);
  transition: all 2.2s ease-out;
}
.translate-counter-500
{
  will-change: transform;
  transform: translateY(-500%);
  -webkit-transform: translateY(-500%); /* For Safari */
  -moz-transform: translateY(-500%);    /* For Firefox */
  -ms-transform: translateY(-500%);     /* For Internet Explorer */
  -o-transform: translateY(-500%);
  transition: all 2.2s ease-out;
}
.translate-counter-1500
{
  will-change: transform;
  transform: translateY(-1500%);
  -webkit-transform: translateY(-1500%); /* For Safari */
    -moz-transform: translateY(-1500%);    /* For Firefox */
    -ms-transform: translateY(-1500%);     /* For Internet Explorer */
    -o-transform: translateY(-1500%);
  transition: all 2.2s ease-out;
  /* transition: all 2.2s cubic-bezier(0,0,0,1); */
}
.fw-medium-bold
{
  font-weight: 600;
}
.min-height-100vh
{
  min-height: 100vh;
}
.object-fit-contain
{
  object-fit: contain;
}
.height-map
{
  height: 40vh;
}
.not-found-fixed-bg 
{
  background-image: url("https://incubeindia.com/incubelandmarks/images/not-found-bg.webp");
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: -1;
} 
.not-found-black-wash 
{
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.722);
  z-index: 0;
}
.bg-blackish
{
  background-color: rgba(0, 0, 0, 0.479);
}
.triangle , .triangle-left
{
  height: 50px;
  width: 50px;
  position: absolute;
  top: 10%;
  transform: rotate(45deg);
  background-color: var(--theme--dark-blue);
}
.height-info 
{
  min-height: 60vh;
}
.gallery-photo
{
  height: 75vh;
  object-fit: cover;
  transition: all 0.6s;
}
.gallery-photo:hover, .gallery-photo:focus
{
  opacity: 0.7;
}
.height-50vh
{
  min-height: 50vh;
}
.enquiry-container.show
{
  transform-origin: right;
  transform: translateX(0vw);
}
.rotate-90
{
  transform: rotate(90deg) translateY(110%);
  position: absolute;
}
.small-screen-navbar
{
  position: fixed;
  top: 0px;
  transform: translateY(-100%);
  transform-origin: top;
  transition: all 0.6s;
  z-index: 100;
  opacity: 0;
}
.small-screen-navbar.show{
  transform: translateY(0%);
  opacity: 1;
}
.height-8vh
{
  height: 8vh;
}
.position-fixed-navbar
{
  position: fixed;
  top: 0px;
}
.bg-youtube
{
  background-color:  #FF0000;
}


.chatbot-screen
{
  position: fixed;
  top: 0%;
  right: 0px;
  transition: all 0.5s;
  height: 80vh;
  width: 100vw;
  z-index: 999;
}
.chatbot-screen.active{
  transform: translateY(30vh);
  transition: all 0.5s;
}
.chatbot-screen:not(.active){
  transform: translateY(89vh);
  transition: all 0.5s;
}
.chatbot-toggle-btn 
{
  height: auto;
  margin-bottom: -10px;
}
.chatbot-close-btn
{
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
}

/* src/Chatbot.css */
.custom-chatbot {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 60vh;
  /* margin-top: -10%; */
}

.custom-chatbot .rsc-header {
  background-color: var(--theme--dark-blue) !important;
  color: var(--theme-light-grey) !important;
  font-size: 0.8em !important;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 600 !important;
  font-style: normal !important;

}

.custom-chatbot .rsc-container {
  border-radius: 0px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.custom-chatbot .rsc-bot-message {
  background-color: var(--theme-red) !important;
  color: var(--theme-light-grey) !important;
  font-size: 0.8em !important;
}

.custom-chatbot .rsc-user-message {
  background-color: var(--theme-grey) !important;
  color: var(--theme-dark-grey) !important;
  font-size: 0.8em !important;
}

.custom-chatbot .rsc-input {
  border: 1px solid var(--theme-light-grey) !important;
  border-radius: 0px;
}

.hiexti {
  background-color: transparent !important;
  fill: var(--theme-red) !important;
}

.rsc-bubble, .rsc-bubble-text {
  font-size: 12px !important; /* Adjust the font size here */
  border-radius: 0% !important;
}

.golQDh
{
  border-radius: 0px 0px 0px 0px !important;
}
.rsc-bubble {
  border-radius: 0px !important; /* Remove border-radius for bot bubbles */
}

.rsc-user {
  border-radius: 0px !important; /* Remove border-radius for user bubbles */
}
.isqngw
{
  overflow: auto !important;
  border-radius: 0px !important;
  font-size: 0.8em !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  max-width: 80% !important;
  
}
.cpXMvM
{
  border-radius: 0px !important;
  font-size: 0.8em !important;
  font-family: "Roboto", sans-serif  !important;
  font-weight: 400  !important;
  font-style: normal  !important;
}
.kCKfbM
{
  border-radius: 0px !important;
  font-size: 0.8em !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
.jpJyWr, .iAuOXT:disabled
{
/* type message */
  font-size: 0.9em !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
.cVRauN
{
  height: 100% !important;
}
.fBGGBn
{
  height: calc(100% - 112px) !important;
}
.video-container
{
  height: 100vh;
  background-color: var(--theme-dark-blue-transparent);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: -10;
  opacity: 0;
  transition: all 0.6s ease-in-out;
} 
.video-container.show{
  z-index: 10000;
  opacity: 1;
}
.height-video
{
  height: 65vh;
}
.toggle-navbar
{
  position: fixed;
  top: 0px;
  z-index: 100;
}
.other-articles-image
{
  height: 10vh;
  object-fit: cover;
}